
































































import {Vue, Component } from "vue-property-decorator";
import {Filtro} from "@/Classes/FiltroClass";
import StruttureService, {StruttureResponse} from "@/services/StruttureService";
import EntrateUsciteServices from "@/services/EntrateUsciteServices";
import {DateTime} from "luxon";

class Form {
  categoria: number
  descrizione: string
  prezzo: number
  tipo: string
  data: Date

  public constructor() {
    this.categoria = 0
    this.descrizione = ''
    this.prezzo = 0
    this.tipo = ''
    this.data = new Date()
  }
}
@Component
export default class EntrateUscite extends Vue{

  public filtro = new Filtro();
  public form: Form = new Form();
  public datiStruttura: StruttureResponse = []
  public listaCategorie: any = []
  public listaTipi:any = [{id:0, descrizione: 'entrata'},
    {id: 1, descrizione: 'uscita'}]

  rules = {
    tipo: [{required: true, message: 'Selezionare il tipo di operazione'}],
  }
  public async RiceviStrutture(){
    try{
      const response = await StruttureService.getStrutture();

      if(response.error) {
        this.$message.error(response.msg)
      } else{
        this.datiStruttura = response.data;
        this.filtro.id_struttura = this.datiStruttura[0].struttura.value
      }
    }
    catch (err:any)
    {

    }
  }

  public async RiceviCategorie(){
    try{
      const response = await EntrateUsciteServices.GetCategorie()
      if(response.error) {
        this.$message.error(response.msg)
      } else {
        this.listaCategorie = response.data
        this.form.categoria = this.listaCategorie[0].id
        console.log(this.listaCategorie)
      }
    }catch (err: any){

    }
  }

  public async add(){
    let dataselected = DateTime.fromJSDate(this.form.data)
    let data = DateTime.utc(dataselected.year, dataselected.month, dataselected.day, 12, 0, 0)
    const body = {
      categoria: this.form.categoria,
      descrizione: this.form.descrizione,
      prezzo: this.form.prezzo * 100,
      tipo: this.form.tipo,
      data: data.toString()
    }
    if(this.form.prezzo > 0){
      const response = await EntrateUsciteServices.AggiungiOperazione(this.filtro.id_struttura, body)
      if(response.error){
        this.$message.error(response.msg)
      } else {
        this.$alert("L'operazione è stata aggiunta con successo", 'Congratulazioni', {
          confirmButtonText: 'OK',
          callback: action => {
            this.$message({
              type: 'info',
              message: `action: ${ action }`
            });
            this.$router.go(0)
          }
        });
      }
    } else{
      this.$alert("L'importo non può essere pari a 0", 'Attenzione', {
        confirmButtonText: 'OK',
      });
    }

  }

  mounted(){
    this.RiceviStrutture();
    this.RiceviCategorie();
  }
}
